import React from 'react';
import { Link } from 'gatsby';

import SEO from '../components/SEO';

import TermsSection from '../components/CommonSections/TermsSection';

const PrivacyPage = () => (
  <>
    <SEO title="IKEA Giveaway campaign Terms and Conditions" />
    <TermsSection 
      title="IKEA Giveaway Terms and Conditions"
      content={(<SecondSection />)}
    />
  </>
);

const SecondSection = () => (
    <>
      <h2>Giveaway Campaign: £475 IKEA Voucher Terms and Conditions</h2>
      <p>
        We are giving away a £475 IKEA voucher to one of our lucky followers. To be entered in the giveaway:
      </p>
      <p>
        <ul>
          <p>
            1. You must be following <a
              href="https://www.instagram.com/spriveapp/"
              target="_blank"
              rel="noopener noreferrer"
            >@spriveapp</a> on Instagram
          </p>
          <p>
            2. You must tag 2 people in the comments of the giveaway post between Monday 17th July 2023 00:00 and 23:59 Sunday 23rd July 2023.          
          </p>
          <p>
            3. You must reshare the post on your story between Monday 17th July 2023 00:00 and 23:59 Sunday 23rd July 2023. (If you are a private account, please send us a screenshot via Instagram DM as proof)
          </p>
        </ul>
      </p>

      <p>
        Entries to this giveaway will be open from Monday 17th July 2023 at 00:00 until 23:59 on Sunday 23rd July 2023.
      </p>

      <p>
        The winner will be chosen at random and will be contacted from our Instagram DM by Tuesday 25th July 2023 and announced.
      </p>

      <p>
        We will only contact you from our official Sprive IG account <a
              href="https://www.instagram.com/spriveapp/"
              target="_blank"
              rel="noopener noreferrer"
            >@spriveapp</a>
      </p>

    </>
);

export default PrivacyPage;
